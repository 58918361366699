import React from 'react'
import * as styles from './style.module.sass'

import ArrowSP from '../../../images/arrow-sp.inline.svg'
import ArrowPC from '../../../images/arrow-pc.inline.svg'
import HoverProvider from '../../atoms/HoverProvider'

const ProjectsTagFilterButton = (props) => {

  const clickEvent = () => {
    props.changeOpenStatus(!props.isOpen);
  }

  return(
    <HoverProvider
      hoverClassName={styles.ProjectsTagFilterButtonIsHover}
    >
      <button
        className={
          props.isOpen? styles.ProjectsTagFilterButton + ' ' + styles.ProjectsTagFilterButtonIsActive : styles.ProjectsTagFilterButton
        }
        onClick={() => {clickEvent();}}
      >
        <span className={styles.ProjectsTagFilterButtonIcon + ' ' + styles.ProjectsTagFilterButtonIconPC}><ArrowPC/></span>
        <span className={styles.ProjectsTagFilterButtonIcon + ' ' + styles.ProjectsTagFilterButtonIconSP}><ArrowSP/></span>
        Material
      </button>
    </HoverProvider>
  )
}

export default ProjectsTagFilterButton
