import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/organisms/seo"
import ProjectsTemplate from "../components/template/ProjectsTemplate"

const IndexPage = ({ data }) => {
  return (
    <>
      <Seo title="Projects" />
      <ProjectsTemplate
        allProjects={data.allProjects}
        allCategories={data.allCategories}
        allTags={data.allTags}
      />
    </>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query {

    allProjects: allWpPost(sort: {order: DESC, fields: date}) {
      edges {
        node {
          title
          slug
          projectTitleIsJp {
            isJpTitle
          }
          projectArchiveField {
            indexVimeoUrl
            archiveImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    breakpoints: [750, 812, 2000]
                    quality: 100
                    width: 2000
                  )
                }
              }
            }
            archiveSpImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    breakpoints: [1280]
                    quality: 100
                    width: 1280
                  )
                }
              }
            }
          }
          tags {
            nodes {
              name
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }

    allCategories: allWpCategory(filter: {count: {gt: 0}}, sort: {fields: id, order: ASC}) {
      edges {
        node {
          name
        }
      }
    }

    allTags: allWpTag(filter: {count: {gt: 0}}) {
      edges {
        node {
          name
        }
      }
    }
  }
`
