import React from "react"
import * as styles from './style.module.sass'

import HoverProvider from '../../atoms/HoverProvider'

const ProjectsCategorySort= (props) => {

  const getButtonClass = (_name) => {
    let baseClassName = styles.ProjectCategorySortButton;
    if(props.activeCategory == _name) baseClassName += ' ' + styles.ProjectCategorySortButtonIsActive;
    return baseClassName;
  }

  const changeCategory = (_name) => {
    props.changeCategory(_name);
  }

  return(
    <ul className={styles.ProjectCategorySort}>

      <li
        key={-1}
        className={styles.ProjectCategorySortItem}
      >
        <HoverProvider
          hoverClassName={styles.ProjectCategorySortButtonIsHover}
        >
          <button
            className={getButtonClass('All')}
            onClick={() => changeCategory('All')}
          >
            All
          </button>
        </HoverProvider>
      </li>

      {props.allCategories.edges.map((category, id) => (
        <li
          key={id}
          className={styles.ProjectCategorySortItem}
        >
          <HoverProvider
            hoverClassName={styles.ProjectCategorySortButtonIsHover}
          >
            <button
              className={getButtonClass(category.node.name)}
              onClick={() => changeCategory(category.node.name)}
            >
              {category.node.name}
            </button>
          </HoverProvider>
        </li>
      ))}
    </ul>
  )
};

export default ProjectsCategorySort
