import React, {useState, useRef, useEffect, useCallback} from "react"
import { memo } from "react"
import * as styles from './style.module.sass'

import ProjectsTagFilterButton from "../../atoms/ProjectsTagFilterButton";
import ProjectsTagItem from "../../atoms/ProjectsTagItem";

const DeviceSwitchWidth = 768;

const ProjectsTagSort = memo((props) => {
  const [isOpen, changeOpenStatus] = useState(false);
  const [isActiveAllButton, setActiveAllButton] = useState(true);

  const ticking = useRef(false);
  const bodyDom = useRef(null);
  const bodyHeight = useRef(0);

  const tglOpenTag = (_status) => {
    changeOpenStatus(_status);
    setBodyDomHeight();
  }

  const setBodyDomHeight = (_rev) => {
    if(bodyDom.current==null) return;
    if(window.innerWidth >= DeviceSwitchWidth+1 || (isOpen&&!_rev || !isOpen&&_rev)) {
      if(props.tagAreaHeight == 0) return;
      props.changeTagHeight(0);
      return;
    }
    bodyHeight.current = bodyDom.current.clientHeight;
    if(props.tagAreaHeight == bodyHeight.current) return;
    props.changeTagHeight(bodyHeight.current);
  }

  const resizeEventTicking = () => {
    if( !ticking.current ) {
      window.requestAnimationFrame( () => {
        setBodyDomHeight(true);
        ticking.current = false;
      });
      ticking.current = true;
    }
  }

  useEffect(() => {
    if(typeof window === 'undefined') return;
    window.addEventListener(`resize`, resizeEventTicking);
    return () => window.removeEventListener(`resize`, resizeEventTicking)
  }, [])

  useEffect(() => {
    setBodyDomHeight(true);
  }, [])

  return(
    <>
      <div className={styles.ProjectsTagSortToggleButton}>
        <ProjectsTagFilterButton
          layoutName={props.layoutName}
          isOpen={isOpen}
          changeOpenStatus={(_status) => tglOpenTag(_status)}
        />
      </div>

      <ul
        className={
          isOpen? styles.ProjectsTagSortList + ' ' + styles.ProjectsTagSortListIsOpen : styles.ProjectsTagSortList
        }
        ref={bodyDom}
      >
        <li
          className={styles.ProjectsTagSortItem + " " + styles.ProjectsTagSortItemAll}
        >
          <ProjectsTagItem
            layoutName={props.layoutName}
            tag={null}
            activeTags={props.activeTags}
            changeTags={(_tags) => props.changeTags(_tags)}
            allTags={props.allTags}
            isActiveAllButton={isActiveAllButton}
            setActiveAllButton={(_state)=>setActiveAllButton(_state)}
          />
        </li>
        {props.allTags.edges.map((tag, id) => (
          <li
            key={id}
            className={styles.ProjectsTagSortItem}
          >
            <ProjectsTagItem
              layoutName={props.layoutName}
              tag={tag}
              activeTags={props.activeTags}
              changeTags={(_tags) => props.changeTags(_tags)}
              allTags={props.allTags}
              isActiveAllButton={isActiveAllButton}
              setActiveAllButton={(_state)=>setActiveAllButton(_state)}
            />
          </li>
        ))}
      </ul>
    </>
  )
});

export default ProjectsTagSort
