import React from 'react'
import { memo } from "react"
import * as styles from './style.module.sass'

const ProjectsTagItem = memo((props) => {

  const clickEvent = () => {
    if(props.activeTags!==undefined && props.activeTags.includes(props.tag.node.name)) {
      // delete from array
      const arr = JSON.parse(JSON.stringify(props.activeTags)).filter(item => (item != props.tag.node.name))
      props.changeTags(arr);
      if(arr.length == 0) props.setActiveAllButton(false);
    }else{
      // add to array
      const arr = JSON.parse(JSON.stringify(props.activeTags));
      arr.push(props.tag.node.name);
      props.changeTags(arr);
      if(arr.length == props.allTags.edges.length) props.setActiveAllButton(true);
    }
  }

  const addAll = () => {
    // add all
    const arr = [];
    for(let i = 0; i < props.allTags.edges.length; i++) {
      arr.push(props.allTags.edges[i].node.name);
    }
    props.setActiveAllButton(true);
    props.changeTags(arr);
  }

  const deleteAll = () => {
    // dell all
    const arr = [];
    props.setActiveAllButton(false);
    props.changeTags(arr);
  }

  const clickAllEvent = () => {
    if(props.activeTags===undefined && !props.isActiveAllButton) addAll();
    else if(props.activeTags.length==props.allTags.edges.length && props.isActiveAllButton) deleteAll();
    else addAll();
  }

  return(
    props.tag != null ? (
      <button
        className={
          props.activeTags===undefined? styles.ProjectsTagItem : props.activeTags!=[] && props.activeTags.includes(props.tag.node.name)? styles.ProjectsTagItem + ' ' + styles.ProjectsTagItemIsActive : styles.ProjectsTagItem
        }
        onClick={() => {clickEvent()}}
      >
        {props.tag.node.name}
      </button>
    ) : (
      <button
        className={
          !props.isActiveAllButton? styles.ProjectsTagItem : styles.ProjectsTagItem + ' ' + styles.ProjectsTagItemIsActive
        }
        onClick={() => {clickAllEvent()}}
      >
        All
      </button>
    )
  )
});

export default ProjectsTagItem
