import React, {useState, useCallback, useEffect, useRef} from "react"
import * as styles from './style.module.sass'

import ProjectsSort from "../../organisms/ProjectsSort"
import ProjectList from "../../organisms/ProjectList"
import FvFooter from "../../organisms/FvFooter"

const ProjectsTemplate = (props) => {
  const layoutName = useRef('/projects/');
  const scrollTarget = useRef(null);
  const [activeCategory, changeCategory] = useState('All');
  const [activeTags, changeTags] = useState([]);
  const allTags = useRef([]);
  const [isHide, changeHideStatus] = useState(typeof window === 'undefined'? null : document.getElementsByTagName('html')[0].classList.contains('is-fixed'));
  const [tagAreaHeight, changeTagHeight] = useState(0);
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  );

  useEffect(() => {
    const arr = [];
    for(let i = 0; i < props.allTags.edges.length; i++) {
      arr.push(props.allTags.edges[i].node.name);
    }
    changeTags(arr);
  }, [])

  useEffect(() => {
    if(typeof window === 'undefined') return;
    //- mutation observer https://qiita.com/ryo_hisano/items/9f15ae87d691d497bc17
    const target = document.getElementsByTagName('html')[0];
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if(mutation.target.classList.contains('is-fixed')) changeHideStatus(true);
        else{ changeHideStatus(false); }
      });
    });
    const config = {attributes:true};
    observer.observe(target, config);
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div
        className={styles.container}
        id={"container"}
      >
        <div
          className={styles.containerWrap}
        >
          <div className={
            isHide? styles.projectsSort + ' ' + styles.projectsSortIsHide : styles.projectsSort
          }>
            <ProjectsSort
              allCategories={props.allCategories}
              allTags={props.allTags}
              activeCategory={activeCategory}
              changeCategory={useCallback((_cat) => changeCategory(_cat), [activeCategory])}
              activeTags={activeTags}
              changeTags={useCallback((_tags) => changeTags(_tags), [activeTags])}
              changeTagHeight={useCallback((_height) => changeTagHeight(_height), [tagAreaHeight])}
              tagAreaHeight={tagAreaHeight}
              layoutName={layoutName.current}
            />
          </div>
          <div>
            <ProjectList
              projectArray={props.allProjects.edges}
              activeCategory={activeCategory}
              activeTags={activeTags}
              tagAreaHeight={tagAreaHeight}
              layoutName={layoutName.current}
            />
          </div>
        </div>
      </div>
      {!isMobile.current && (
        <FvFooter
          scrollTarget={scrollTarget.current}
          layoutName={layoutName.current}
        />
      )}
    </>
  )
}

export default ProjectsTemplate
