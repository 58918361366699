import React, {useCallback} from "react"
import * as styles from './style.module.sass'

import ProjectsTagSort from "../../molecules/ProjectsTagSort"
import ProjectsCategorySort from "../../molecules/ProjectsCategorySort"

const ProjectsSort = (props) => {
  return(
    <div className={styles.ProjectsSort}>
      <div className={styles.ProjectsSortItem}>
        <ProjectsCategorySort
          layoutName={props.layoutName}
          allCategories={props.allCategories}
          activeCategory={props.activeCategory}
          changeCategory={useCallback((_cat) => props.changeCategory(_cat), [props.activeCategory])}
        />
      </div>
      <div className={styles.ProjectsSortItem}>
        <ProjectsTagSort
          layoutName={props.layoutName}
          allTags={props.allTags}
          activeTags={props.activeTags}
          changeTags={(_tags) => props.changeTags(_tags)}
          changeTagHeight={useCallback((_height) => props.changeTagHeight(_height), [props.tagAreaHeight])}
          tagAreaHeight={props.tagAreaHeight}
        />
      </div>
    </div>
  )
}

export default ProjectsSort
